import {queryStringToObject} from "./tools/url";

const query = queryStringToObject(window.location.search.substring(1));
const isDebug = !!query["debug"];
const isProduction = window.location.origin.indexOf("toondao.com") > 0;
const clientToken = window.localStorage["tooncoin:client_token"] || makeClientToken();
const splitGroup = parseInt(query["split_group"]
  || window.localStorage["tooncoin:user_split_group"]
  || (Math.floor(Math.random() * 10) + 1)
);

window.localStorage["tooncoin:client_token"] = clientToken;
window.localStorage["tooncoin:user_split_group"] = splitGroup;
window.localStorage["tooncoin:first_visit_at"] = window.localStorage["tooncoin:first_visit_at"] || Date.now();

const config = {
  loadedUrl: new URL(window.location.href),
  query: query,
  isDebug: isDebug,
  isProduction: isProduction,
  isMobile: /iphone|ipad|android/i.test(window.navigator.userAgent),
  isMetaMask: /metamask/i.test(window.navigator.userAgent),
  clientToken: clientToken,
  userSplitGroup: splitGroup,
  locale: query["locale"] || query["lang"] || window.navigator.language || undefined,
  contract: {
    address: {
      mainnet: "0xE8078B5198E572Be8D8D412511d48b7D0f5E9a1c",
      goerli: "0x7E3BA0bCD192155ac7b9E51613C639e4e026d2dD",
    },
    abiPath: "./R2d2V0Extended.abi.json",
  },
  services: {
    infura: {
      key: "5871ff536f3d43e59131d71f88c2b0b5",
    },
  },
};

export default config;

function makeClientToken() {
  let platformSegment = "u";
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    platformSegment = "a";
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    platformSegment = "i";
  }

  let result = "";
  const map = "abcdef0123456789";
  for (let i = 0; i < 32; i++) {
    result += map.charAt(Math.floor(Math.random() * map.length));
  }

  return ["b", platformSegment, result].join(":");
}