export function isMetaMaskInstalled() {
  return window.ethereum && window.ethereum.isMetaMask;
}

export function getMetaMaskDeeplink(url, params = {}) {
  const _url = new URL(url);

  if (typeof params === "object") {
    for (let key in params) {
      _url.searchParams.append(key, params[key]);
    }
  }

  return "https://metamask.app.link/dapp/" + _url.toString().replace(/^https?:\/\//i, "");
}