import config from "../config";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {getMetaMaskDeeplink, isMetaMaskInstalled} from "./metamask";
import {logEvent, userEvents} from "../tools/log";
import {gaEvents, gaSendEvent} from "../tools/ga";
import {hit, hits} from "../tools/hits";

const svgNs = "http://www.w3.org/2000/svg";

let web3 = null;

if (window.localStorage["walletconnect"] != null) {
  getWalletConnectProvider().then((provider) => {
    web3 = new Web3(provider);
  });
} else {
  detectEthereumProvider({mustBeMetaMask: true}).then((provider) => {
    provider.request({method: "eth_accounts"}).then((accounts) => {
      if (accounts.length > 0) {
        web3 = new Web3(provider);
      }
    });
  })
}

function getMetaMaskProvider() {
  return detectEthereumProvider({mustBeMetaMask: true});
}

function getWalletConnectProvider() {
  const provider = new WalletConnectProvider({
    infuraId: config.services.infura.key,
  });

  return provider.enable().then(() => {
    return provider;
  });
}

export function getAuthorizedAccounts() {
  // метамаск не даст аккаунт через web3.eth.getAccounts
  // пока браузерный экстеншен просит пароль для разблокировки кошелька
  // поэтому запрашиваем аккаунты через request метод, чтобы метамаск отобразил юзеру окно с вводом пароля
  if (web3.eth.currentProvider.isMetaMask) {
    return web3.eth.currentProvider.request({method: "eth_requestAccounts"});
  } else {
    return web3.eth.getAccounts();
  }
}

export function authorize(params) {
  const _options = Object.assign({
    force: false,
    metaMaskDeeplinkUrl: null,
  }, params);

  if (_options.force) {
    window.localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
    window.localStorage.removeItem("walletconnect");

    if (web3 !== null) {
      web3 = null;
    }
  }

  if (web3 !== null) {
    return Promise.resolve(web3);
  } else {
    return authorizeFlow(_options);
  }
}

function authorizeFlow(params) {
  const popup = createPopup();
  const closePopup = () => popup.remove();

  document.body.appendChild(popup);

  hit(hits.ethConnect);

  return new Promise((resolve, reject) => {
    popup.querySelector(".popup__close").addEventListener("click", () => {
      closePopup();
      reject("User cancel authorization");
    });

    popup.querySelector(".js-connect-mm").addEventListener("click", () => {
      gaSendEvent(gaEvents.ethConnect, {provider: "metamask"});
      logEvent(userEvents.ethConnect, {provider: "metamask"});

      if (isMetaMaskInstalled()) {
        getMetaMaskProvider().then((provider) => {
          closePopup();
          resolve(provider);
        })
        .catch(function(err) {
          gaSendEvent(gaEvents.ethConnectError, {
            provider: "metamask",
            error_message: err.message,
            error_code: err.code
          });
          logEvent(userEvents.ethConnectError, {
            provider: "metamask",
            error_message: err.message,
            error_code: err.code
          });
          hit(hits.ethConnectError);

          reject(err);
        });
      } else {
        if (config.isMobile) {
          window.location.href = getMetaMaskDeeplink(params.metaMaskDeeplinkUrl || window.location.href);
        } else {
          window.open("https://metamask.io/");
        }
      }
    });

    popup.querySelector(".js-connect-wc").addEventListener("click", () => {
      gaSendEvent(gaEvents.ethConnect, {provider: "walletconnect"});
      logEvent(userEvents.ethConnect, {provider: "walletconnect"});

      getWalletConnectProvider().then((provider) => {
        closePopup();
        resolve(provider);
      }).catch(function(err) {
        gaSendEvent(gaEvents.ethConnectError, {
          provider: "walletconnect",
          error_message: err.message,
          error_code: err.code
        });
        logEvent(userEvents.ethConnectError, {
          provider: "walletconnect",
          error_message: err.message,
          error_code: err.code
        });
        hit(hits.ethConnectError);

        reject(err);
      });
    });
  }).then((provider) => {
    gaSendEvent(gaEvents.ethConnected, {
      provider: provider.isMetaMask ? "metamask" : "walletconnect",
    });
    logEvent(userEvents.ethConnected, {
      provider: provider.isMetaMask ? "metamask" : "walletconnect",
    });
    hit(hits.ethConnected);

    web3 = new Web3(provider);

    return getAuthorizedAccounts();
  }).then((accounts) => {
    logEvent(userEvents.authorizedAccounts, {accounts: accounts});

    Promise.all(accounts.map((account) => web3.eth.getBalance(account)))
      .then((balances) => {
        logEvent(userEvents.authorizedAccountsBalances, balances.map((balance, i) => {
          return {address: accounts[i], balance};
        }));
      });

    return web3;
  });
}

function createPopup() {
  const popupContainer = document.createElement("div");
  popupContainer.classList.add("popup-container", "popup-container_wallet");

  const popup = document.createElement("div");
  popup.classList.add("popup");
  popupContainer.appendChild(popup);

  const buttonsContainer = document.createElement("div");
  buttonsContainer.classList.add("popup__btns-container");
  popup.appendChild(buttonsContainer);

  const title = document.createElement("h3");
  title.classList.add("popup__title");
  title.innerText = "choose one:";
  buttonsContainer.appendChild(title);

  const metaMaskButton = document.createElement("button");
  metaMaskButton.classList.add("popup__btn", "js-connect-mm");
  buttonsContainer.appendChild(metaMaskButton);

  const metaMaskButtonImage = document.createElement("img");
  metaMaskButtonImage.src = "/img/seeklogo.png";
  metaMaskButtonImage.alt = "";
  metaMaskButton.appendChild(metaMaskButtonImage);

  const metaMaskButtonTitle = document.createElement("h4");
  metaMaskButtonTitle.innerText = "MetaMask";
  metaMaskButton.appendChild(metaMaskButtonTitle);

  const metaMaskButtonText = document.createElement("p");
  metaMaskButtonText.innerText = "Connect to your MetaMask Wallet";
  metaMaskButton.appendChild(metaMaskButtonText);

  const walletConnectButton = document.createElement("button");
  walletConnectButton.classList.add("popup__btn", "js-connect-wc");
  buttonsContainer.appendChild(walletConnectButton);

  const walletConnectButtonImage = document.createElement("img");
  walletConnectButtonImage.src = "/img/walletconnect.png";
  walletConnectButtonImage.alt = "";
  walletConnectButton.appendChild(walletConnectButtonImage);

  const walletConnectButtonTitle = document.createElement("h4");
  walletConnectButtonTitle.innerText = "WalletConnect";
  walletConnectButton.appendChild(walletConnectButtonTitle);

  const walletConnectButtonText = document.createElement("p");
  walletConnectButtonText.innerText = "Use WalletConnect to connect";
  walletConnectButton.appendChild(walletConnectButtonText);

  const closeButton = document.createElement("button");
  closeButton.classList.add("popup__close");
  popup.appendChild(closeButton);

  const closeButtonSvg = document.createElementNS(svgNs, "svg");
  closeButtonSvg.setAttributeNS(null, "viewBox", "0 0 320 512");
  closeButton.appendChild(closeButtonSvg);

  const closeButtonSvgPath = document.createElementNS(svgNs, "path");
  closeButtonSvgPath.setAttributeNS(null, "d", "M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z");
  closeButtonSvg.appendChild(closeButtonSvgPath);

  return popupContainer;
}